/*
 * Flex UX Framework
 *
 * Filename: checkout-addresses-order-type.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */



	.checkout-side-top--addresses {
		.checkout-small-text {
    		padding-bottom: 10px;
    		font-size: 14px;
			padding-top: 3px;
			padding-bottom: 3px;
			line-height: 1.25;
			color: $brand-color;
		}

		.mdl-radio {
			display: inline-block;
			width: 100%;

			&:first-of-type{
				margin-top: 5px;
			}

			span{
				font-size: 14px;
				color: $brand-color;
			}
		}
	}
