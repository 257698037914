/*
 * Flex UX Framework
 *
 * Filename: price.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.price {
	font-weight: 500;
	font-family: $primary-font-face;
	font-size: 16px;
}