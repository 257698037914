/*
 * Flex UX Framework
 *
 * Filename: category.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.category {
	
}

.category-list {

	main{
		background-color: #FFF;
		min-height: calc(100vh - 65px) !important;
		padding-top: 175px;

		@include mq('tablet', 'max'){
			padding-top: 56px;
		}
	}

	.category-msg{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 42px;
		text-align: center;
		padding: 10px;
		margin: 0px;
		background: white;
		padding: 8px;
		line-height: 1.25em;
		border-bottom: 1px solid #E5E5E5;

		p{
			text-align: center;
			font-size: calc(12px + 0.25vw);
			font-weight: 400;
			color: #333;
			margin: 0;
			line-height: 1.25em;
			max-width: 565px;

			@include mq('tablet', 'max') {
				max-width: 555px;
				@media only screen and (max-width: 900px){
					max-width: 60%;
					@media only screen and (max-width: 800px){
						max-width: 65%;
						@media only screen and (max-width: 760px){
							max-width: 70%;
							@media only screen and (max-width: 700px){
								max-width: 100%;
								margin: 0 10vw;
								@media only screen and (max-width: 500px){
									margin: 0 5vw;
								}
							}
						}
					}
				}
			}
		}
	}

	.page-header {
		background: #E0F4FD; 
		margin-top: $header-height;
		height: 175px; 
		//display: flex;
		//justify-content: center;
		//align-items: center; 
	}

	.page-body {
		font-family: $primary-font-face;
		font-size: 13px;
		display: flex;
		flex-wrap: wrap;
		max-width: 1180px;
		margin-top: 24px;
		@include mq("tablet", "min") {
			padding: 0px 20px;
		}
		
		.products-container {
			flex-grow: 1;
			flex-basis: calc(100% - 260px);
			order: 4;
			overflow-x: hidden;

			@include mq('tablet', 'max') {
				margin-right: 0;
			}

			@include mq("phone-wide", "max") {	
				margin: 0px -6px 0;
				padding: 0px 9px 9px 9px;
			}
		}

		.grid-container {
    		display: flex;
    		flex-flow: row nowrap;
		}

		@include mq('tablet', 'max') {
			flex-direction: row-reverse;
		}
		@include mq("phone-wide", "max") {
		    padding: 15px 6px 6px 6px;
		    margin-top: 0; 
		}
	}

	.products-header {
    	flex: 3 75%;
    	order: 1;
    	// height: 41px;
    	padding-bottom: 5px;
    	margin-bottom: 15px;
    	
    	@include mq('tablet', 'max') {
			align-self: center;
			order: -1;
	    	flex: 2 2 50%;
	    	display: flex;
	    	justify-content: flex-end;
	    	margin-bottom: 5px;
	    	padding-bottom: 0;
		}	

    	@include mq('tablet', 'min') {
    		display: flex;
			flex-flow: row;
    		flex-wrap: nowrap;
			flex-wrap: nowrap;
			flex: 4 4 100%;
    	}

    	@include mq('phone-small', 'max') {
	    	flex: 2 2 30%;
			width: 10px;
		}

    	.title {
    		flex: 1 1 50%;
			font-size: 30px;
			// height: 48px;
			font-weight: 400;
			font-family: $primary-font-face;
			color: $brand-color;
			margin: auto;

			@include mq('tablet', 'min') {
				// line-height: 62px;
			}

			//visibility: hidden;
    	}

	}

}