/*
 * Flex UX Framework
 *
 * Filename: checkout-addresses-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-address-controls {
    display: flex;
    padding: 16px;
    justify-content: flex-end;

    @include mq("tablet", "max"){
	    flex-flow: column;
		align-items: center;
	}

	// button{
		// min-height: 45px;
		// height: auto;
		// line-height: 20px;
		
	 //    -webkit-box-shadow:inset 0px 0px 0px 2px #65B1FB;
	 //    -moz-box-shadow:inset 0px 0px 0px 2px #65B1FB;
	 //    box-shadow:inset 0px 0px 0px 2px #65B1FB;
	 //    background-color: $brand-color-btn-secondary;
	 //    color: #3B3B3B !important;
	 //    &:hover {
	 //        background-color: $brand-color-btn-secondary-hover!important;
	 //        color: white;
	 //    }
	// }

	// .mdl-button:not(:last-child) {
	//     // @include mq("tablet", "min"){
	//     	margin-right: 20px;
	// 		background-color: #53565A !important;
	// 		width: 275px;
	// 		padding: 0px 15px;
	// 	// }
	// }

	.mdl-button{
	    // @include mq("tablet", "min"){
	    	margin-right: 20px;
			background-color: #53565A !important;
			width: 275px;
			padding: 0px 15px;
		// }

		@media only screen and (max-width: 919px){
			margin-right: 0px !important;
		}
	}

	.mdl-button:hover{
		background-color: #424447 !important;
	}

	
}




