/*
 * Flex UX Framework
 *
 * Filename: checkout-payment-gateways.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-payment {

	.checkout-bottom--gateways {
		width: 100%;
		background: #fff;
	}

}