/*
 * Flex UX Framework
 *
 * Filename: checkout-payment-invoice.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */



#gateway-NCC-ACCOUNT{
	.payment-columns{

		.payment-column-two-bottom{
			height: 100%;

			#continue-button-invoice{
				margin: auto auto auto auto;
			}
		}
	}
}