/*
 * Flex UX Framework
 *
 * Filename: item-romance-mobile.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product-description-text {
    font-family: $primary-font-face;
    line-height: 1.8;

	@include mq("tablet", "max"){
		line-height: 2;
	}
}

.product-description-accordions {
	border: none;
	@include mq("tablet", "max"){
		line-height: 2;
	}

	.mdl-accordion.mdl-collapse--opened{
		border: none !important;
		background-color: #FAFAFA;
	}

	@include mq('phone-wide', 'min'){
		height: 0;
	    overflow: hidden;
	}

	.product-description-text{
		padding: 15px;
	}

	.mdl-accordion > .mdl-button {
	    width: auto !important;
	    font-weight: 700;
	    font-family: $primary-font-face;
	    color: #333;
	    font-size: 14px;
	}

}