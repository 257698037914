/*
 * Flex UX Framework
 *
 * Filename: header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


// NOTE: This file makes exceptions to the standard skinning process by determining its
//       own position. This is because the header is in a fixed position at all times.

header {
	position: fixed;
	width: 100%;
//	background: $brand-color;
	background: $header-background;
	z-index: 99; 
	height: $header-height;  

	
	&.header-nav-container--landing {
		img {
			bottom: unset !important; 
		}

		.main-nav {
			height: 100%; 
		}
	}

	@include mq('tablet', 'max') {
		height: 56px;
	}

	.nav-1{
		height: $top-bar-height;
		display: flex;
		justify-content: flex-end;
		background: $header-background;

		.welcome {
			padding: 4px 10px 4px 158px;
			font-size: 15px;
			text-transform: none;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			background: $header-background;

			span {
				text-transform: capitalize;
				display: inline-block; 
				font-size: 15px;
				font-weight: 700;
				color: $primary-font-color;
			}
			
		}

		@include mq('tablet', 'max') {
			display: none;	 
		}

		ul{
			padding: 0px 5px;
			margin: 0;
			height: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			li {
				display: flex;
				justify-content: flex-end;
				list-style-type: none;
				font-size: 15px;
				padding: 0px 12px; 
				cursor: pointer;
				font-weight: 700;
				//height: 100%; 
				display: flex;
				align-items: center; 

				&:hover {
					a {
						color: #7A9A01;
						text-decoration: underline;
					}
				}

				a {
					text-decoration: none;
					text-transform: capitalize;
					display: inline-block;
					color: #53565A;
					font-size: 15px; 
					font-weight: 500; 
				}
			}
		}
	}


	.site-header {
		height: $header-top-height;
		background: $header-background;  
		font-weight: 400;
		font-family: $primary-font-face;
		font-size: 10px; 
		display: flex;
		justify-content: space-between;
		align-items: center;

		.desktop-logo{
			display: flex;
			align-self: flex-start;
			.logo {
				    max-width: none;
					max-height: none;
					height: 31px;
					width: 244px;
	                padding-left: 25px; 
	                //margin-bottom: 4px;
					position: absolute; 
					padding-bottom:47px;

					@include mq('tablet', 'max') {
	                	margin-bottom: 0px;
	                	padding-left: 10px; 
						position: relative;
						top: unset;
						height: 38px;

					}				
					    


				@include mq('tablet', 'max') {
					max-height: calc(#{$header-tablet-height} - #{$header-logo-padding});
				//	margin-left: $header-logo-padding;
					bottom: unset;
					top: 0;
					 
				}

				@include mq('phone-wide', 'max') {
					max-height: calc(#{$header-phone-height} - #{$header-logo-padding});
				//	margin-left: $header-logo-padding;
				max-width: 140px; 

				}
			}
		}

		@include mq('tablet', 'max') {
			display: none;
		}

		div {
			display: flex;
		}

		&__welcome {
			padding: 7px 0;
			padding-left: 158px;
			font-size: 13px;
			text-transform: none;

			span {
				margin-left: 25px;
				color: #FFFFFF;
				font-size: 14px;
				font-weight: 400;
				text-align: right;
				&:first-of-type{
					font-weight: 700;
				}
			}
		}

		&__nav-menu {
			margin: 0;
			margin-right: 30px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			li {
				display: inline-block;
				list-style-type: none;
				font-size: 14px;
				padding: 0px 12px; 
				cursor: pointer;
				font-weight: 700;
				height: 100%; 
				display: flex;
				align-items: center; 

				&:hover {
					a {
						text-decoration: underline;
						color: #E6F0F4;
					}
				}

				a {
					text-decoration: none;
					text-transform: capitalize;
					display: inline-block;
					color: #4A4A4A;
					font-size: 15px; 
					font-weight: 400; 
				}
			}

		}

		.site-header__left {
			position: absolute;
			left: 0;
			top: 0;
			max-height: $header-top-height;

			@media only screen and (min-width: #{$body-max-width}) {
				left: calc((100vw - #{$body-max-width}) / 2);
			}

			.mdl-textfield {
				padding: 0;
			}
		}

	}

	.main-nav {
		display: flex;
		flex-flow: row nowrap;
		//align-items: center;
		justify-content: space-between;
		font-weight: 700;
		padding-left: 0px;
		padding-right: 35px;
		height: $header-main-height;
		color: #fff;
		font-family: $primary-font-face;
		background: $brand-color;
		// border-bottom: 1px solid #53565A;
		// border-top: 1px solid #DBDBDB;
		// border-bottom: 1px solid #DBDBDB;

		@include mq('tablet', 'max') {
			height: $header-tablet-height;
			padding-right: 49px;
			background: #fff;
			border-bottom: 1px solid #E5E5E5;
		}

		@include mq('phone-wide', 'max') {
			height: $header-phone-height;
			padding-right: 49px;
			border-bottom: 1px solid #E5E5E5;
		}

		&__left{
			display: flex;
			align-items: flex-start;
		}
		&__right {
			display: flex;
			align-items: center;
		}

	}

	.main-nav__right {
		flex-flow: row;
		//flex-direction: column;
		//align-items: flex-end;

		&.desktop{
			padding-right: 65px;
		}

		&.mobile{
			@include mq('tablet', 'min') {
				position: absolute;
				right: 10px;
				top: 70px;
			}
		}

		.mdl-button--search {
			top: 3px;
			bottom: 0;
			left: 0;
			// margin-left: 5px;
			

			&:hover {
			    background-color: #F2F2F2;
			}

			.material-icons {
				font-size: 24px;
				height: 24px;
				width: 24px;
				color: #7A9A01;
				
			}

		}

		.mdl-textfield--expandable {
			padding: 0;
			// width: 215px;
			display: flex;
			align-items: center;
			// border: 1px solid #DBDBDB;
			border-radius: 25px;
			margin-left: 12px;
		}

		#searchField {
			// min-width: 180px;
			// // border-bottom: none;
			// padding-left: 10px;
		}

		.search-desktop {
			display: flex;
			margin: 0;
			align-items: center;
			//padding-right: 10px;
		}

		.search-textfield-container {
			position: relative;
			height: 35px;
			border-radius: 3px;
			margin-right: 7px;
			// background: white;

			.mdl-textfield {
				&__expandable-holder {
					margin-left: 25px;
					// border-bottom: 1px solid $brand-color;
				}
			}
			
			.material-icons {
				color: #7A9A01;
				// margin-top: 3px;
				margin-left: 3px;
			}

			.mdl-textfield__input {
				// background-color: red;
				border-color: none;
				
			}

			@include mq('tablet', 'max') {
				display: none;
			}

		}

		.material-icons {
			margin-right: 1px;
			color: #7A9A01;
			transform: translate(-14px, -11px);
		}

		.cart {
			// margin-left: 30px;

			.cart-container {
				width: 34px;
				height: 34px;

				&:hover{
					background-color: #F2F2F2;
				}

				.material-icons::after {
					border: 1px solid $header-minicart-badge-border;
					width: 12px;
					height: 12px;
					top: -3px;
					right: -1px;
					font-size: 9px;
					background: $header-minicart-badge-color;
					color: #fff;
					font-family: $primary-font-face !important;
					font-weight: 500;
				}

			}

			.mdl-menu {
				&__container {
					right: -15px;
					top: 44px;
					max-height: calc(100vh - 108px);
					margin-top: 8px;
					//basket image removal
					// width: 345px;
					width: 430px;

					@include mq('tablet', 'min') {
						height: 295px !important;
					}

					.shopping-cart {
						padding: 0;
						width: 430px;
						// width: 345px;
						max-width: 100vw;
						display: flex;
						flex-flow: column nowrap;
						color: $primary-font-color; 
 
						.cart-header {
							padding: 0 20px;
							border-bottom: 1px solid rgba(226, 226, 226, 0.5);
							height: 80px;
							display: flex;
							align-items: center;
							box-sizing: border-box;

							@include mq('tablet', 'max') {
								height: 50px;
								min-height: 50px;
							}

							.cart-title {
								font-size: 23px;
								line-height: 1.75;
								font-weight: 400;
								text-transform: uppercase;
								color: #53565A;

								@include mq('tablet', 'max') {
									font-size: 19px;
								}

							}

						}

						.cart-item-container {
							overflow-y: auto;
							flex-grow: 1;
							padding: 0 20px;
							max-height: 472px;

							.cart-item {
								display: flex;
								align-items: center;
								height: 135px;
								box-sizing: border-box;
								position: relative;
								button{
									.material-icons {
										// color: #000;
										color: #7A9A01;
									}
								}

								button.remove-from-cart{
									&:hover{
										background-color: #F2F2F2;
									}
								}

								.cart-item-img {
									display: flex;
									// display: none;
									width: 85px;

									img {
										margin: auto;
										max-height: 85px;
										max-width: 85px;
									}

								}

								.cart-item-text {
									flex: 1;
									display: flex;
									flex-wrap: wrap;
									margin-left: 30px;
									font-size: 13px;

									.cart-item-name {
										text-transform: uppercase;
										flex: 2 100%;
										margin-bottom: 25px;

										a {
											font-weight: 500;
											font-size: 13px;
											line-height: 16px;
											text-decoration: none;
											color: $primary-font-color;
											&:hover{
												color: unset;
											}
										}
									}

									.cart-item-quantity {
										flex: 1 50%;
										font-weight: 500;
									}

									.cart-item-price {
										flex: 1 50%;
										color: $brand-color-secondary;
										

										.price{
											font-weight: 500;
										}
									}
								}
							}
						}

						.cart-footer {
							border-top: 1px solid rgba(226, 226, 226, 0.5);
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0 20px;
							height: 80px;

							.checkout-btn{
								color: #fff;
								// padding-bottom: 5px;
								// padding-top: 5px;
								height: 45px;
								line-height: 45px; 
							}

							.checkout-btn:hover{
								background-color: #43566D !important; 
							}

							@include mq('tablet', 'max') {
								height: 59px;
								min-height: 59px;
							}

							.cart-subtotal {
								font-size: 18px;
								display: flex;
								font-weight: 500;
								flex-direction: column;
								justify-content: center;
								align-items: flex-start;
								color:$brand-color-secondary;

								.price{
									font-weight: 500;
									font-size: 18px;
								}

								@include mq('tablet', 'max') {
									font-size: 15px;

									.price{
										font-size: 15px;
									}
								}

							}
						}

					}

				}

			}

		}

		.side-nav{
			ul{
				padding: 0px 5px;
				margin: 0;
				height: 100%;
				display: flex !important;
				list-style: none;

				li {
					display: flex;
					justify-content: center;
					list-style-type: none;
					font-size: 14px;
					padding: 0px 12px; 
					cursor: pointer;
					font-weight: 700;
					//height: 100%; 
					display: flex;
					align-items: center; 

					&:hover {
						a {
							color: #008733;
						}
					}

					a {
						text-decoration: none;
						text-transform: capitalize;
						display: inline-block;
						color: #4A4A4A;
						font-size: 14px; 
						font-weight: 400; 
					}
				}
			}
		}
	}

	.main-nav__left {
		flex-flow: column;
		// padding: 0px 10px;

		.logo{
			display: flex;
		}

		@include mq('tablet', 'max') {
			.logo{
				display: none;
			}

			display: flex;
			flex-flow: row;
			align-items: center;
		
		}
		   
		 @include mq('phone-wide', 'max') {
              justify-content: space-between; 
		 }



		.desktop-nav {
			@include mq('tablet', 'max') {
				display: none !important;
			 } 

			display: flex;
			flex-flow: row wrap;
			padding-top: 9px;
			margin: 0px;
			padding: 0px; 
			position: relative;
			
			ul{
				justify-content: left;
				padding-inline-start: 175px;
				// padding-inline-start: 11px;

				&:first-of-type {
					position: absolute;
				}
			}

				& > ul {
				// padding-left: 0px;
				flex: 1 1 100%;
				display: flex;
				height: 48px;
				width: 100vw;
				margin: 0px; 
				padding-left: 10px;
			}

			& > ul > li {
				position: relative;
				display: inline-block;
				list-style: none; 
			    height: 48px;

			    &:nth-child(7) {
			    	// margin-left: auto;
			    }
			   
			}  

			& > ul > li[data-subnav] {
				position: relative;
				display: inline-block;
				list-style: none;

				&:nth-of-type(2){
					padding-left: 12px;
				}

				.sub-nav-item {
					a {
						color: $primary-font-color;
						font-size: 14px;
						text-align: left;
						font-weight: 500; 
						&:hover {
														
						} 
					} 
				}

				.nav-handle {
					// text-align: left;
					position: relative; 
					padding-right: 32px;

					a[href$="ABC/products"], 
					a[href$="ABC/charities"]{
						pointer-events: none;
					}
				}

				> .nav-handle {

					::after {
						font-family: 'Material Icons';
						content: '\e5cb';
						display: inline-block;
						transform: rotate(-90deg);
						font-size: 25px;
						color: #7A9A01;
						position: absolute;
						clip: rect(6px, 22px, 32px, 0px);
						font-weight: 300;
					}
					//&:hover{
					//	::after{
					//		color: #CCE1E9;
					//	}
					//}
				}



				.sub-nav {
					z-index: 10;
					padding: 0;
					display: none;
					opacity: 0;
					padding: 8px 0px;
					.sub-nav-item {
						position: relative;
						margin: 0;
						cursor: pointer;
						display: block;
						white-space: nowrap;
						min-width: 200px;
					}

					.nav-handle {
						padding: 0 24px;
						height: unset; 

						&:hover {
							background-color: #F2F2F2; 
							> a  {
								// color: $link-color; 
							}
						}
					}

				}

				.sub-nav [data-subnav] {
					& > .nav-handle {
						padding-right: 45px;
						width: calc(100% - 69px);

							// &::after {
							// 	font-family: 'Material Icons';
							// 	content: '\e5cc';
							// 	display: inline-block;
							// 	font-size: 24px;
							// 	color: $brand-color;
							// 	position: absolute;
							// 	// right: 10px;
							// 	clip: rect(6px, 22px, 32px, 0px);
							// 	text-decoration: none !important; 
							// } 
                         
                         a {
                         	&:hover {
                         	
                         		::after {
                         			text-decoration: none !important;  
                         		
                         		} 
                         	}

                         	&::after {
								font-family: 'Material Icons';
								content: '\e5cc';
								display: inline-block;
								font-size: 24px;
								color: #7A9A01;
								position: absolute;
								right: 10px;
								clip: rect(6px, 22px, 32px, 0px);
								text-decoration: none !important; 
							}
                        }
					}

					.subSubNavContainer {
						//display: none;
						position: absolute;
						top: 0;
						left: 99.5%;
					}

				}

			}
 
			& > ul > li {

				&:empty{
                    display: none;
                }

				.nav-handle {
					border-radius: 0;
					padding: 0; 
					display: flex;
					flex-flow: column;
					justify-content: center;
					height: 50px;
					padding-left: 14px;
                    padding-right: 14px;

					&:hover {
					    background-color: #3E4144;
					    a{
							// color: $brand-color-secondary;  
						}
					}

					&:nth-of-type(3) {
						width: 100px; 
					}

					&-line-1-5{

						&:blank{
							display: none;
						}
					}
				}
 
				a {
					color: #fff;
					text-transform: none;
					text-decoration: none;
					font-family: $primary-font-face;
					font-weight: 500;
					font-size: 16px;

				}

				.nav-handle-line-1 a {
					// font-weight: 400;  
					flex-shrink: 1;
					text-transform: uppercase;

					span{
						padding: 0px;
						flex-shrink: 1;
						white-space: nowrap;
					}
				}

				// .nav-handle-line-1-3 a{
				// 	pointer-events: none;
				// }

			}
		}

		.mobile-logo {
				display: none;
			    max-width: none;
				max-height: none;
				height: 47px;
                padding-left: 0px; 

				@include mq('tablet', 'max') {
					display: flex;
                	margin-bottom: 0px;
                	padding-left: 10px; 
					position: relative;
					top: unset;
					height: 38px;
					width: 140px;

				}				

			@include mq('tablet', 'max') {
				max-height: calc(#{$header-tablet-height} - #{$header-logo-padding});
			//	margin-left: $header-logo-padding;
				bottom: unset;
				top: 0;
				 
			}

			@include mq('phone-wide', 'max') {
				max-height: calc(#{$header-phone-height} - #{$header-logo-padding});
			//	margin-left: $header-logo-padding;
				max-width: 140px; 
				padding-left: 15px;
			}

		}

	}
}


	.secondary-nav {
		height: $header-top-height;
		background: $brand-color;  
		font-weight: bold;
		font-family: $primary-font-face;
		font-size: 10px; 
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-right: -10px;

		@include mq('tablet', 'max') {
			display: none;
		}

		div {
			display: flex;
		}

		&__nav-menu {
			margin: 0;
 			padding-inline-start: 0px !important;
 			padding-left: 0px;
			height: 100%;
			display: flex;
			align-items: center;

			li {
				display: inline-block;
				list-style-type: none;
				font-size: 13px;
				padding: 0px 12px; 
				cursor: pointer;
				font-weight: 700;
				height: 100%; 
				display: flex;
				align-items: center; 
		
				&:hover {
					background: $brand-color-secondary; 
					a{
						color: #CCE1E9;
					}
				}

				a {
					text-decoration: none;
					text-transform: capitalize;
					display: inline-block;
					color: #FFFFFF;
					font-size: 14px; 
					font-weight: 700; 
				}

			}

		}

	}

.mobile-drawer-nav {
	position: fixed;
	z-index: 100;
	pointer-events: none;
	font-family: $primary-font-face;

	.drawer-logo-container {
		background: $drawer-mobile-background;
		height: $header-mobile-height;
		padding: 0px;
		display: flex;
    	align-items: center;
    	border-bottom: 1px solid #e7e7e7;

		.close-menu{
			position: absolute;
			right: 5px;
			top: 18px;
			color: #7A9A01 !important;
			cursor: pointer;
			margin-right: 10px;
			&:hover{
				color: $link-hover !important;
			}
		}

		img {
			height: 38px;
			margin-left: 10px !important;
			max-height: calc(56px - 10px); 
			
			@include mq("tablet", "max"){
			width: 140px;	
			}
			

			@include mq('phone-wide', 'max') {
				max-height: calc(#{$header-phone-height} - #{$header-logo-padding});
			//	margin-left: $header-logo-padding;
				max-width: 140px; 
			}
		}
	}

	.secondary-nav a{
		color: red !important;
	}

	.mobile-nav-page-controller {
		margin-bottom: 10px;
		position: relative;
		overflow: hidden;
		-webkit-transition: height 0.3s;
	}

	.mobile-nav-page {
		position: absolute;
		width: 100%;
		-webkit-transition: margin 0.3s;
	}

	.mdl-layout__drawer {
		&.is-visible {
			transform: translateX(0);
			pointer-events: all;
		}

		background: #fff;
		font-weight: 400;
		border: none;
		text-transform: capitalize;
		width: 280px;
		transform: translateX(-280px);

		&-button {
			color: $header-icons-color;
			top: 1px;
			left: auto;
			right: -4px;
			pointer-events: all;
 
			height: 50px;
			justify-content: center;
			align-items: center;
		}

	}

	.mdl-navigation {
		padding-top: 0;
		font-size: 14px;

		span{
			font-weight: 700;
		}

		ul {
			font-size: 14px;
			padding-left: 20px;
		}

	}

	ul {
		.mobile-nav-handle {
			a {
				color: $primary-font-color;
				font-weight: 700;  
				font-size: 14px; 
				text-transform: capitalize; 
			}

		}

		[data-subnav] {
			i.material-icons {
				color: #7A9A01;
			}

		}

		li {
			display: flex;
			margin-bottom: 10px;

			a{
				font-weight: 700;
			}
		}

	}


	.back-button {
		cursor: pointer;
		padding-left: 20px;
		margin-bottom: 8px;
		display: flex;
		line-height: 24px;
		color: #333;

		i.material-icons {
			color: #7A9A01;
			margin-right: 10px;
		}

	}

	.sub-nav-title {
		font-weight: bold;
		color: #333;

		&:before {
			content: "•";
			font-size: 23px;
			line-height: 25px;
			color: $brand-color;
			margin-right: 6px;
		}

	}

	.page-right {
		margin-left: 100%;
	}

	.page-left {
		margin-left: -100%;
	}

	.search-textfield-container {
		padding: 0 20px;
		padding-right: 15px;

		.mdl-textfield {
			padding: 27px 0;
			width: 100%;
			margin-left: -11px;
			padding-top: 14px !important;
		}

		.mdl-textfield__input {
			position: relative;
			top: 6px;
			text-align: left;
		}

		input {
			width: calc(100% - 40px);
			margin-left: 40px;
			border-bottom: 1px solid lightgray;
			border-radius: 0;
		}

		.mdl-textfield__label {
			width: calc(100% - 40px);
			margin-left: 40px;
		}

		.mdl-textfield.is-dirty, .mdl-textfield.is-focused {
			.mdl-textfield__label {
				color: $brand-color !important;
			}

		}

		i {
			color: $brand-color;
		}

	}

}

nav ul li a {
	text-decoration: none;
	color: #7A9A01;
}




.page-header {
    margin-top: ($header-height);   

	@include mq("tablet", "max") {
		margin-top: $header-mobile-height; 
	}

	//@include mq("tablet", "max") {
	//	flex-flow: column-reverse nowrap;
	//}
}

.page-header, .page-header .category-header-img {
	@include mq('phone', 'max') {
// 		height: 100px; 
	}
}

.mdl-textfield.is-focused{
	outline: red;
}