/*
 * Flex UX Framework
 *
 * Filename: basket-empty.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout-basket-empty {
	text-align: center;

	@include mq("tablet", "max"){
    	margin: 10px;
	}

	.btn {
   		color: #fff;
		background-color: #53565A !important;
		width: 230px;
	}

	.btn:hover {
		color: #fff;
	 background-color: #424447 !important;
	 width: 230px;
 }

    p {
    	margin-bottom: 16px;
    }
}
