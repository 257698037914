/*
 * Flex UX Framework
 *
 * Filename: contact-us-content.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.contact-us {

	.page-body {

		address, p {

    		font-size: 14px;
    		line-height: 1.5em;
    		color: $primary-font-color;

		}

		// a {

  //   		color: $brand-color;

		// }

		address {
			margin-bottom: 14px;
		}
	}
}