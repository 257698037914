/*
 * Flex UX Framework
 *
 * Filename: item-options.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product {

	&-options-container {
    	position: relative;
    	box-sizing: border-box;
    	border: 1px solid #E5E5E5;
    	padding: 20px;
    	margin-top: 30px;

    	@include mq("tablet", "max"){
    		padding: 10px;
    	}

    	.select-option{

    		&-btn{
    			background-color: #FAFAFA;

    			&:hover{
    				background-color: #F2F2F2;
    			}
    		}
    	}

    	.mdl-menu__item{
    		color: #333;
    		&:hover {
        		background-color: #F2F2F2;
        		color: $brand-color;
    		}

    		a:hover{
        		color: $brand-color;
    		}
		}

    }

    &-quantity-details {
    	display: flex;
    	align-items: center;
    	
    	.product-quantity {
    		min-width: 60px;
			width: 60px;
			margin-right: 25px;

			form {
				display: flex;
				align-items: center;

				&:before {
					content: "QTY: ";
					font-family: $secondary-font-face;
					margin-right: 5px;
					font-weight: 500;
					font-size: 14px;
				}
			}
    	}

    	.quantity-available{
    		// display: none;
    	}
	}

	&-image-option{
		&-title{
		    text-transform: uppercase;
		    font-family: $secondary-font-face;
		    font-size: 14px;
		    margin-bottom: 10px;
	    	font-weight: 500;
	    	color: $primary-font-color;
		}

		&-name{
			// color: $primary-font-color;
			color: $brand-color-secondary;
		}

		&-thumbnails {
		    display: flex;
		    margin-left: -5px;
		    flex-wrap: wrap;
		}

		&-thumbnail{
			height: 70px;
		    width: 70px;
		    margin: 5px;
		    text-align: center;
		    cursor: pointer;

			&.active, &:hover {
		    	outline: 2px solid $brand-color-secondary;
			}
		}
	}
}

.product-quantity-details  {
	.product-quantity{ 
		form{
    		margin-bottom: 0;
			input{
		    	font-size: 13px;
		    	font-family: $secondary-font-face;
		    	color: $primary-font-color;
		    	font-weight: 700;
			}	
		} 

		.mdl-textfield.is-dirty {
		    color: #333;
		}

		.mdl-textfield {
		    color: #BFBFBF;
		}

		.mdl-textfield.is-dirty .mdl-textfield__label {
		    color: $brand-color;
		}
	}

	.quantity-available {
	    font-weight: 500;
	}

	.remove-btn {
	    margin-left: auto;
	    font-size: 14px;
	    // letter-spacing: 2px;
	    font-family: $primary-font-face;
	    color: #333;
	    font-weight: 700;

	    &:hover{
	    	background-color: #FAFAFA;
	    }
	}

}
