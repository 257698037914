/*
 * Flex UX Framework
 *
 * Filename: home.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.home {
	.about-us {
    	//margin: 12px 12px 32px 12px;
    	//margin: 24px 0px ;
	}

	.featuredboxes{
		margin: 12px 12px 18px 12px;

		@media only screen and (max-width: 440px){
		margin: 12px 0px 11px 0px;	
		}
		

		&--content{
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			max-width: 948px;

			@media only screen and (max-width: 978px) {
				justify-content: center;
			}

			@media only screen and (max-width: 670px) {
				justify-content: space-between;
			}

			.fbox{
				max-width: 300px;
				min-width: 300px;
				// min-width: 465px !important;
				height: 200px;
				display: inline-block;
				background-position: center;
				// background-color: #CDE1D7;

				a{
					color: #ffffff;
					text-decoration: none;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 300px;
					height: 200px;
					line-height: 1.15em;
					// text-shadow: -1px 1px 5px rgba(0,0,0,0.75);
					background-repeat: no-repeat;
					background-position: bottom;
					width: 100%;
  					// font-family: "Nunito Sans";
  					font-size: 36px;
  					font-weight: 800;
  					letter-spacing: 0;
  					text-align: center;

					.featuredTextSpan {  
						width: 280px;
    					height: 50px;
						color: #323537;
						font-weight: 500;
						margin-top: 116px;
						line-height: 50px;

  					@media only screen and (max-width: 400px) {
	 					font-size: 28px;
	 				}

	 				@media only screen and (max-width: 350px) {
	 					font-size: 25px;
	 				}
					}	 
				}
			}

			.fbox1 {
				margin-bottom: 15px;
				a {
					background: url(../images/cat1-items.png) no-repeat scroll bottom;
					background-color: #B1C273;
				}

				@media only screen and (max-width: 978px){
					max-width: 620px;
  					min-width: 620px;
  					margin-bottom: 23px;
				}

				@media only screen and (max-width: 670px) {
	 				min-width: 100%;
	 				margin-bottom: 4%;
	 			}

	 			@media only screen and (max-width: 480px) {
	 				max-height: 175px;
	 				height: 175px;

	 				a {

	 					height: 175px;
	 					// background-size: calc(175px + 15vw);
	 					background-size: contain;
						 background-position: top 5px center;
	 				}		
	 			}
			}

			.fbox2 {
				
				a {
					background: url(../images/cat2-items.png) no-repeat scroll center;
					background-color: #B1C273;
					background-size: contain;
				}

				@media only screen and (max-width: 978px){
					margin-right: 10px;
				}

				@media only screen and (max-width: 670px) {
	 				min-width: 48%;
	 				width: 48%; 
	 				margin-right: 0;			
	 			}

	 			@media only screen and (max-width: 480px) {
	 				max-height: 175px;
	 				height: 175px;

	 				a {
						background: url(../images/cat2-items-mobile.png) no-repeat scroll center;
	 					height: 175px;
						background-size: contain;
						background-color: #B1C273;
	 				}		
	 			}
			}

			.fbox3 {
				a {
					background: url(../images/cat3-items.png) no-repeat scroll center;
					background-color: #B1C273;
					background-size: contain;
					background-position: top 5px center;
				}

				@media only screen and (max-width: 978px){
					margin-left: 10px;
					
				}

				@media only screen and (max-width: 670px) {
	 				min-width: 48%;
	 				width: 48%;
	 				margin-left: 0;
					 background-position: center;
	 			}

	 			@media only screen and (max-width: 480px) {
	 				max-height: 175px;
	 				height: 175px;
					background-image: unset;

	 				a {
						background: url(../images/cat3-items-mobile.png) no-repeat scroll center;
	 					height: 175px;
						background-size: contain;
						background-color: #B1C273;
	 				}		
	 			}

	 			@media only screen and (max-width: 330px) {
	 				width: 48%;
	 			}
			}

			.fbox4 {
				a {
					background: url(../images/cat2-items.png) no-repeat scroll center;
					background-color: #B1C273;
					background-size: contain;
				}

				@media only screen and (max-width: 978px){
					margin-right: 10px;
				}

				@media only screen and (max-width: 670px) {
	 				min-width: 48%;
	 				width: 48%; 
	 				margin-right: 0;			
	 			}

	 			@media only screen and (max-width: 480px) {
	 				max-height: 175px;
	 				height: 175px;

	 				a {
	 					height: 175px;
	 				}		
	 			}
			}
		}

		h1{
			color: $brand-color;
			text-align: center;
			margin-bottom: 20px;
			position: relative;
			font-size: 30px;
			z-index: 1;
			overflow: hidden;
			font-weight: 500;
		}
	}

	
	.page-body{
		padding: 1px 12px;

		@include mq('tablet', 'max') {
			padding: 0 10px;
		}
		
		//Featured Producst item list
		.product-list{
			// margin-bottom: 63px;

			@media only screen and (max-width: 996px) and (min-width: 745px){
				margin-left: 13vw;
				margin-right: 13vw;
			}

			@media only screen and (max-width: 440px){
				margin-bottom: 7px;
			}
			
		}
	}

	hr{
		height: 2px;
		width: 50px;
		outline: none;
		border: none;
		background-color: $brand-color-secondary;
		margin: 0 0 1em 0;
	}
	
	.welcome {

		display: flex;
		flex-flow: column;
		justify-content: center;
    	align-items: center;
    	text-align: center;
    	padding: 20px;
    	background-color: #fff;
    	padding-top: 40px;
    	padding-bottom: 40px;

    	@include mq("tablet", "max"){
			flex-direction: column;
		}

		h1 {
			padding-bottom: 10px;
			color: $brand-color;
			font-family: $primary-font-face;
			font-size: 30px;
			text-align: center;
			line-height: 1.15;

			// @include mq('phone-wide', 'max') {
			// 	padding-top: 15px;
			// }
		}

		p {
			color: $primary-font-color;
			font-family: $primary-font-face;
			font-size: 14px;
			line-height: 1.5em;
			max-width: 720px;
			margin: 0;
			padding-bottom: 8px;

			&:nth-of-type(2) {
				padding-bottom: 0;
			}
		}
	}

	.custom-orders {
		min-height: 233px;
		background-color: #8C8C8C;
	
		padding: 24px;
	

		&__container {

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			max-width: 948px;
			margin: 0 auto;
			background-color: #FFFFFF;
			height: 100%;
			text-align: center;
			padding: 15px;
			flex: 1 0 auto;

			h1 {
				color: $brand-color;
				font-family: $primary-font-face;
				font-size: 34px;
				text-align: center;
				text-transform: capitalize !important;
			}
 
			p {
				color: $primary-font-color;
				font-family: $primary-font-face;
				font-size: 14px;
				line-height: 1.5em;
				max-width: 567px;
				padding: 10px;

				@include mq("phone-wide", "max"){
					max-width: 100%;
				}
			}

		}
	}
}