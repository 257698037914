/*
 * Flex UX Framework
 *
 * Filename: faqs-renderer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.faqs {

	.page-body {

		.mdl-accordion {
			margin-top: 12px;
  			.mdl-accordion__icon {
    			margin-left: auto;
			}
		}
	}

	.mdl-accordion__button {
    	font-weight: 700;
    	font-size: 14px;
    	margin-left: 5px;
    	margin-top: 5px;
    	cursor: pointer;
        line-height: 1.15em;
        font-family: $secondary-font-face;
        color: $primary-font-color;
        opacity: 1;
	}

	.mdl-collapse{

        &__content {
    	   padding: 12px;
    	   font-size: 14px;
    	   line-height: 1.5em;
    	   bottom: 10px;
    	   // color: #666;
    	   font-family: $primary-font-face;

    	   p {
                line-height: 1.5em;
    	   	   font-size: 14px;
		  }

		  a {
    		  color: #004990;

                &:hover {
                    color: #002548;
              }
		  }
	   }

       &--opened{
            background-color: #fff;
            border-top: 0px solid #E5E5E5;
            border-bottom: 0px solid #E5E5E5;
       }
    }	
}