/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.home {

 	main {
 		//background: #f2f2f2;
 	}
 	
 	.page-body {
 		max-width: 972px;
 		// margin:  30px auto auto;
 		padding: 12px;
 		background: inherit;

 		@include mq("phone-wide", "max") {
		    padding: 6px;
		    margin-top: 0;
		}
 	}
 }

.hero-slide{
	display: flex;
	justify-content: center;

	.content{
		display: flex;
		align-items: center;
		justify-content: space-around;
		// width: 100%;
		// max-width: 1040px;
		width: 100%;
		background: url(../images/spot-items.png) no-repeat scroll bottom;
		background-size: contain;
		background-position-x: right;
		margin-left: 5vw;
		margin-right: 5vw;

		@media only screen and (max-width: 919px){
			background: url(../images/spot-items-mob.png) no-repeat scroll bottom;
		}

		@media only screen and (max-width: 650px){
			background: url(../images/spot-items-mob.png) no-repeat scroll bottom;
			background-size: contain;
			background-position-x: right;
			width: unset;
			margin: unset;
			
		}

		@media only screen and (max-width: 550px){
			background: none;
			background-size: contain;
			background-position-x: right;
			margin: unset;
			
		}

		@include mq('phone-wide', 'max'){
			//margin-right: -5vw;
			//padding-right: 36px;
			// padding: 30px 30px;
			//padding-left: 0px;
		}

		@include mq('desktop-wide', 'min'){
			margin-left: 14vw;
			margin-right: 7vw;
		}


		.text{
			display: flex;
			flex-flow: column;	
			margin: auto;
			margin-left: 0;
			padding-top: 21px;
    		padding-left: 36px;
			padding-bottom: 21px;

			@media only screen and (max-width: 919px){
				text-align:center;
			}
			
			@media only screen and (max-width: 650px){
				padding-left: 0px;
			}

			@media only screen and (max-width: 550px){
				padding-left: 0px;
			}

			@include mq('tablet', 'max'){
				//padding-left: 0px;
				margin: unset;
			}

		

			@media only screen and (max-width: 650px) {
				//padding-left: 0px;
				margin:unset;
			}

			@include mq('phone-wide', 'max'){
				padding-left: 0px;
			}

			@include mq('phone', 'max'){
				padding-left: 0px;

			}

			h1{
				color: #FFFFFF;
				font-size: 50px;
				line-height: auto;
				//font-size: calc(12px + 3.6vw);
				// font-size: calc(14px + 4vw);
				line-height: 61px;
				font-weight: 400;
				text-align: left;
				font-family: $primary-font-face;
				text-shadow: 1px 2px 8px rgba(0,0,0,0.83);
				margin-top: 5px;
				

				// border-bottom: 4px solid #fff;

				// width: 300px;
				padding-right: 34px;
				// box-shadow: 0 6px 6px -6px rgba(0,0,0,0.75);
				
				@media only screen and (max-width: 650px){
					margin: 0 auto;
					margin-right: 36px;
					text-align: left;
				}
		
				@media only screen and (max-width: 550px){
					margin: 0 auto;
					margin-right: 36px;
					text-align: cenleftter;
				}

				@include mq('tablet', 'max'){
					padding-left: 0;
					margin-right: 36px;
					margin-left: 36px;
					text-align: center;
				}

			

				@media only screen and (max-width: 650px) {
					margin: auto;
					font-size: 44px;
					padding-left:34px;
				}

				@include mq('phone-wide', 'max'){
					width: unset;
					display: inline-block;
					font-size: calc(44px - 2vw);
				}

				@include mq('phone', 'max'){
					font-size: calc(44px - 2vw);
					padding: 0 10px;
					line-height: 1.25;

				}
			
				

			}
			
			h2{
				color: #FFFFFF !important;
				font-weight: 400;
				font-size: 35px;
				//font-size: calc(12px + .6vw);
				// font-size: calc(12px + 1vw);
				// padding-top: 18px;
				// text-shadow: 0 0 6px rgba(0,0,0,0.75);
				font-family: $primary-font-face;
				text-shadow: 1px 2px 8px rgba(0,0,0,0.83);

				@media only screen and (max-width: 650px) {
					margin: auto;
				}
				

				@include mq('tablet', 'max') {
					//margin: auto;
					//padding-left: 0px;
					
				}

				@include mq('phone-wide', 'max'){
					width: 100%;
					margin-left: 0;
					font-size: calc(24px - 1vw);
					text-align: center;
				}

				@include mq('phone', 'max') {
					// padding-top: 10px;
					font-size: calc(24px - -1.5vw);
					text-align: center;
				}
			}	
			
		}
		
	}
}

