/*
 * Flex UX Framework
 *
 * Filename: faqs.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.faqs {

	main {
		background: #fff !important;
	}

    .page-header {
        height: 0;
    }



	.page-body {

    	max-width: 600px;
        margin: 0px auto;
        padding: 12px 12px 50px 12px;
	}
}