/*
 * Flex UX Framework
 *
 * Filename: checkout-payment-column-billing.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container .checkout-bottom--gateways .gateway-tabs {

	.textfield-payment {
		// label{
		// 	color: $brand-color;
		// }
	}

	.is-dirty.is-upgraded{
		input{
			color: #333;
		}

		label{
			color: $brand-color;
		}
	}

	.is-upgraded{
		label{
			color: #737677;
		}
	}

	.is-focused{
		label{
			color: $brand-color;
		}
	}
}

@include mq("tablet", "max"){
	.checkout-container .checkout-bottom--gateways .gateway-tabs .textfield-payment {
	    flex: 1 1 100%;
	    margin-bottom: 0;

	}
	.checkout-container .checkout-bottom--gateways .gateway-tabs .textfield-payment, .checkout-container .checkout-bottom--gateways .gateway-tabs .billing-adjust {
	    max-width: 90%;
	    margin-top: 10px;
	}
}
