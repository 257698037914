
// mdl-accordion grabbed from https://gist.github.com/fotinakis/54540f239f61ebd86b4b

$horizontal-padding: 24;
$button-height: 46;

.mdl-accordion.mdl-collapse--opened {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}

.mdl-accordion.mdl-collapse--opened .mdl-accordion.mdl-collapse--opened {
    margin-top: 1px;
}

.mdl-accordion.mdl-collapse--opened + .mdl-accordion.mdl-collapse--opened {
    border-top: none;
    margin-top: 0;
}

.mdl-accordion .mdl-accordion__icon {
    transition-property: transform;
    transition-duration: 0.2s;
    color: #7A9A01;
}

.mdl-accordion > .mdl-button {

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    height: #{$button-height}px;
    line-height: #{$button-height}px;

    width: calc(100% - #{$horizontal-padding * 2}px);
    letter-spacing: 1px;
    font-size: 12px;
    text-align: left;
}

.mdl-accordion .option-container {

    background: #F5F5F5;

    display: flex;
    align-items: center;
    justify-content: center;
    height: #{$button-height}px;
    padding: 0 $horizontal-padding;

    label {
        cursor: pointer;
    }
}

.mdl-accordion.mdl-collapse--opened {

    // For nesting
    background: rgba(0, 0, 0, 0.03658536);

    & > .mdl-accordion__button > .mdl-accordion__icon {
        transform: rotate(-180deg);
        color: #7A9A01;
    }
}
