/*
 * Flex UX Framework
 *
 * Filename: basket-promo.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .checkout-basket-promo{
    padding-left: 20px;
    padding-right: 20px;
 }

.checkout-basket-promo--btn{
    margin-bottom: 10px;
    

    // @media only screen and (max-width: 479px){
    
    // }
    
}
