/*
 * Flex UX Framework
 *
 * Filename: item-header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product {

	&-details-header, &-title {
    	font-weight: 500;
		font-size: 18px;
		line-height: 1.15;
		height: auto;
		font-family: $primary-font-face;
		text-transform: uppercase;
		color: #53565A;

	}


	&-details-header {
	  	border-bottom: 1px solid #E5E5E5;;
		padding-bottom: 5px;

		.product-title, .product-price{
			@include mq("tablet", "max"){
				// padding-bottom: 10px;
				line-height: 23px;
			}
		}

		.product-sku{
			font-family: $secondary-font-face;
			@include mq("tablet", "max"){
				padding-bottom: 10px;
			}
		}
	}

	&-sku {
		font-weight: normal;
		font-size: 14px;
		padding: 10px 0px;
		color: $primary-font-color;
		text-transform: uppercase;
		line-height: 1;
	}

	&-price {
		font-weight: 700;
		font-size: 20px;
		line-height: 23px;
		display: inline-block;
		text-transform: uppercase;
		width: 48%;
		font-family: $secondary-font-face;

		.price {
			font-weight: 500;
			font-size: 20px;
			color: $brand-color-secondary;
			font-family: $secondary-font-face;
		}
	}

	&-size-chart-toggle {
    	text-align: right;
    	display: inline-block;
    	width: 49%;
    	font-size: 14px;
    	line-height: 1;

    	a {
    		color: $link-color;
    		text-transform: capitalize;
    		//text-decoration: none;

    		&:hover{
    			color: $link-hover;
    		}
    	}
	}
}
