/*
 * Flex UX Framework
 *
 * Filename: footer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.footer {
	display: flex;
	flex-flow: row nowrap;	
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	background: $footer-background;
	color: #FFF;
	font-family: $primary-font-face;
	font-size: 15px;
	height: 25px;
	min-height: 25px;
	// border-top: 1px solid #fff;

	@include mq('tablet', 'min'){
		border-top: 1px solid #fff;
	}

	img {
		height: 44px;
		margin-right: 20px;
		display: none;

		@include mq("tablet", "max") {
		    height: 25px;
		    margin-bottom: 20px;
		    margin-right: 0px;
		}

	}

	a {
		color: #FFF;
		text-decoration: none;
		// text-transform: capitalize;
		font-weight: 400;
		font-size: 15px;
		font-family: $primary-font-face;
		height: 100%;
		width: 100%;
		padding: 0px 10px;
		margin: unset;

		&:hover {
			background-color: #3E4144;
			text-decoration: none;
			// padding: 0px 10px;
		}
	}

	#call-link {
		display: none;
	}

	> div {
		display: flex;
		align-items: center;
		font-size: 15px;
		font-family: $secondary-font-face;
		font-weight: 400;

		@include mq("tablet", "max") {
		    flex-direction: column;
		    justify-content: space-around;
		}

		&:nth-of-type(2){
			@media only screen and (max-width: 479px) {
				margin-top: 30px;
				margin-bottom: 15px;	
			}
		}

	}

	@include mq("tablet", "max") {
		min-height: 150px;
		flex-flow: column nowrap;
		text-align: center;
		padding: 30px;

		#call-link {
			display: inline-flex;

			& > a {
				font-size: 20px;
				font-family: $primary-font-face !important;
			}
		}

		#call-link a {
			
			display: inline-flex;
			text-decoration: none;
		}

		#call-link a::before {
			content: '\e0cd';
			height: 25px;
			width: 25px;
			font-family: "Material Icons";
			font-size: 22px;
			// color: #fff;
			padding-right: 5px;
		}

		.footer-nav-menu {
			padding-top: 0;
		}

		div:nth-child(2){
			margin-top: 15px;
			@media only screen and (max-width: 479px){
				margin-top: 30px;
			}
		}
	}
	@media only screen and (max-width: 479px){
		min-height: 230px;
	}

	
}

