/*
 * Flex UX Framework
 *
 * Filename: myaccount-orderdetail.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.my-account{
	table{
		width: 100%;
	}

	.price{
		font-weight: normal;
		font-size: 13px;
	}

	table:last-of-type > tbody:first-child{
		height:20px;
		text-align: left;
	}
}

.page-body{
	table:nth-child(10){
		tbody{
			tr:nth-child(1){
				th:nth-child(4){
					p{
						strong{
							font-size:13px;
						}	
					}
				}
			}	
		}
	}

	
}