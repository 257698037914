/*
 * Flex UX Framework
 *
 * Filename: item-add-buttons.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.add-another-btn-container {
    padding: 10px 0;

    .add-another-btn {
        font-size: 14px;
        color: $primary-font-color;
        font-family: $secondary-font-face;
        font-weight: 500;
        height: 45px;

        &:hover{
            background-color: #FAFAFA;
        }

        .material-icons.add-icon{
            margin-top: -2px;
            font-size: 24px;
            color: #7A9A01;
        }
    }
}

.add-to-list-buttons {
    padding: 30px 0 20px;
    border: 1px solid #F2F2F2;
    border-left: none;
    border-right: none;
    display: flex;

    @include mq("phone", "max") {
        flex-direction:column;
    }
}

.add-to-wishlist-btn, 
.add-to-cart-btn {
    min-height: 45px;
    width: 197px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #fff;
    border-radius: 3px;
    margin-right: 13px;

    @include mq("phone-wide", "max") {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
    }
}