/*

 * Flex UX Framework

 *

 * Filename: pagination.scss

 * Type:     Partial Stylesheet

 * 

 * Copyright (C) 2019 Geiger, All Rights Reserved

 */



.pagination{
	display: flex;
	justify-content:center;
	color: $brand-color-secondary;
	margin: 30px 0px 50px;

	@include mq("tablet", "max"){
	margin: 30px 0px;
	}
	

	.pagination-left{
		display: flex;
	}

	#pagination-right, #pagination-left{
		&:hover{
			background-color: #fff;
		}	
	}

	.pagination-numbers{
		display: flex;
		list-style-type: none;
		margin: 0px;
		align-items: center;
		padding: 0px;
		text-align: center;
		color: #7A9A01;

		li{
			padding: 0 2px;
			width: 35px !important;
			height: 35px !important;
			line-height: 35px !important;
			margin-right: 4px;
			

			a{
				text-decoration: none;
				color: $primary-font-color;
				font-weight: 700;
			}

			&.is-active {
				color: #fff!important;
				background: #7A9A01!important;

				a {
					color: #fff;
				}
			}

			&:hover {
				background: #7A9A01!important;

				a {
					color: #fff;
				}
			}

		}
	}

	.material-icons {
		color: #7A9A01;
	}

}