/*
 * Flex UX Framework
 *
 * Filename: register.scss
 * Type:     Template Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.landing{
    .main-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0px;
        padding-right: 35px;
        height: $header-height;
        background: $header-background;

        @include mq('tablet', 'max') {
            height: $header-tablet-height;
            padding-right: 49px;
        }

        @include mq('phone-wide', 'max') {
            height: $header-phone-height;
            padding-right: 49px;
        }

        &__logo {
            display: flex;
            align-items: center;
            pointer-events: none;
        }

        .logo {
            max-width: 200px;
            max-height: none;
            height: 54px;
            width: 200px;
            margin-left: 12px;


            @include mq('tablet', 'max') {
                max-height: 35px;
                width: unset;
                max-width: 140px;
                margin-left: $header-logo-padding;
            }


            @include mq('phone-wide', 'max') {
                max-height: calc(#{$header-phone-height} - #{$header-logo-padding});
                margin-left: $header-logo-padding;
                max-width: 125px;
            }

        }
    }

    .spotlight {
        margin-top: $header-height;
        height: 175px;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,132,61,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,132,61,1) 100%);
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,132,61,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#00843d",GradientType=1);
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq('tablet', 'max') {
            height: 150px;
        }

        @include mq('phone-wide', 'max') {
            height: 125px;
        }
        @include mq('phone', 'max') {
            height: 100px;
        }

        @include mq('tablet', 'max') {
            margin-top: $header-tablet-height;
        }

        .wrapper{
            width: 100%;
            height: 100%;
            max-width: 1175px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            @include mq("tablet-small", "max"){
                justify-content: center;
            }
        }
        .title{
            color: #FFFFFF;
            font-family: $primary-font-face;
            font-weight: 800;
            font-size: 40px;
            line-height: 1.15em;
            font-size: calc(18px + 1vw);
            padding: 0px 12px;
            text-align: center;
            flex: 1;
            min-width: 300px;
        }


    }


    .landing-content { 
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .wrapper {
            margin: 12px 0px;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;


            @include mq("tablet", "max")  {
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
            @include mq("phone-wide", "max")  {
                padding: 0px 12px;
            }
        }

        &__box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 195px;
            width: 494px;
            margin: 12px;
            padding: 12px;
            background: #fff;

             @include mq("tablet", "max")  {
                width: 90%;

            }

             @include mq("phone", "max")  {
                margin: 12px 0px;
                padding: 24px 0px;
                width: 98%;
             }

            h1 {
                margin-top: 0px;
                margin-bottom: 24px;
                font-size: 30px;
                text-align: center;
                color: $primary-font-color;
                line-height: 1.15em;
                font-weight: bold;
                padding: 0px 12px;
                }

            p {
                font-size: 14px;
                padding: 20px;
                color: $primary-font-color;
                text-align: center;  
            }

        }

    }
    .footer {
        @include mq("tablet", "max") {
            min-height: 75px;
            flex-flow: column nowrap;
            text-align: center;
            padding: 30px;

            #call-link {
                display: inline-flex;

                & > a {
                    font-size: 20px;
                    font-family: $primary-font-face !important;
                }
            }
        }
    }
}