/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


 .home .main-feature {
 	height: 225px;
 	max-width: 948px;
	// background-image: url("../images/banner-bg.jpg");
	background-size: cover;
    background-position: 15% 45%;
    margin: 0 auto 19px auto;
    background-color: #D8DEE1;

    @include mq('tablet-small', 'max'){
    	max-width: 620px;
    	// margin-left: 12px;
    	// margin-right: 12px;
    }

    @media only screen and (max-width: 660px) {
    	margin-left: 12px;
    	margin-right: 12px;
    }
		
	@include mq("phone-wide", "max") {
		height: 225px;
		
	}

	@media only screen and (max-width: 440px){
		margin: 0 auto 11px auto;
		}
		

	@include mq("phone", "max") {
		height: 175px;
		
	}

 	> div {
	    width: 50%;
	    flex: 1;
    	display: flex;
    	flex-flow: column nowrap;


        &:last-of-type{
        	flex: 1.5;
        	// margin-bottom: 21px;
			margin-right: 44px;
		    background-image: url("../images/banner-items.png");
		    background-size: contain;
		    background-repeat: no-repeat;
		    background-position: bottom center;

			@include mq("tablet", "max"){
				margin-bottom: 35px;
				margin-right: 20px;
				}
				

			@include mq('tablet-small', 'max') {
				background-image: url("../images/banner-items-mobile.png");
				background-position: right;
				flex: 1.5;
				
			}

			@media only screen and (max-width: 660px){
				background-position: right;
				flex: 1;
				margin:unset;
			}

			@media only screen and (max-width: 490px){
				// background-position-x: 8vw;
				background-position-x: 1vw;
				// background-size: cover;
				background-size: 170px;
				
			}

			@include mq('phone', 'max') {
				// background-position-x: 10vw;
				background-position-x: 4vw;
				background-size: 130px;
			}

			@media only screen and (max-width: 350px){
				background-position-x: 0vw;
				width: 40%;
			}

			@media only screen and (max-width: 330px){
				background-position-x: 2vw;
				margin: 10px 0px;
			}
    	}
    }
	.product-info {
    	text-align: center;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		max-width: 350px;
		width: 100%;
		height: 100%;
		padding-left: 53px;

		@media only screen and (max-width: 660px){
			padding-left: 5vw;
		}

		@include mq("phone-wide", "max") {
			padding-left: 28px;
			padding-right: 5px;
		}

		@include mq("phone", "max") {
			padding-left: 18px;
		}

	    h1 {
			color: #323537;
			font-family: $primary-font-face;
			font-size: 36px;
			// font-size: calc(18px + 2vw);
			line-height: 1;
			text-align: left;
			margin: 0;
			font-weight: 800;
			padding-bottom: 19px;

			@media only screen and (max-width: 460px){
				font-size: calc(36px * .90);
				padding-bottom: 3vw;
			}

			@media only screen and (max-width: 405px){
				font-size: calc(34px * .90);
			}

			// @include mq("phone-wide", "max") {
			// 	font-size: calc(17px + 2.19vw);
			// }
		}

		p{
			text-align: left;
			// color: #333333;
  			font-size: 18px;
  			font-weight: 700;
  			letter-spacing: 0;
  			line-height: 1em;
			color: #323537;

  			@media only screen and (max-width: 460px){
				font-size:	16px;
			}
			@media only screen and (max-width: 350px){
				width: 150px;
				font-size: 12.5px;
			}

		}

		button{
			max-width: 155px;
			margin-top: 23px;
			height: 45px;
			font-size: 14px;
			font-weight: 500;
			background-color: #51657D;
			white-space: nowrap !important;
			// margin: 2% 0px;
			// align-self: flex-start;
			// text-transform: capitalize;
			// background: white;
			
			
			
			@media only screen and (max-width: 460px){
				margin-top: 4vw;
				font-size:	16px;
			}

			@media only screen and (max-width: 350px){
				font-size: 13px;
				padding: 0 10px;
				max-width: 120px;
			}
		}
			
    }
}


