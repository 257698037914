/*
 * Flex UX Framework
 *
 * Filename: item.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.product-detail {
	
	main{
		// background-color: #f2f2f2;
		min-height: calc(100vh - 65px) !important;
		padding-top: 175px;

		@include mq('tablet', 'max'){
			padding-top: 56px;
		}
	}

	.page-header {
		margin: unset;
		height: 0;
	}

	&-page-container {
    	width: 100%;
    	padding: 0;
    	max-width: 1175px;
    	margin: 25px auto 12px;
    	font-family: $primary-font-face;

    	@include mq("tablet", "max"){
    		flex-direction: column;
		    width: calc(100% - 20px);
		    margin-top: 10px;
		    margin: 10px auto 20px;
		}

		// @include mq("phone-wide", "max") {
		// 	margin-top: 0;
		// }
	}

	&-panel {
    	// border-radius: 5px;
    	padding: 0;
    	background: none;
    	display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		font-family: $primary-font-face;

		@include mq("tablet", "max"){
			flex-direction:column;
		}
	}

	.product-main-container {

		@include mq("tablet", "min") {
			margin-right: 25px;
    		width: 600px;
    	}

    	.product-image-container{
    		border: 1px solid #E5E5E5;
    	}

    	.product-description-tabs{
    		border: 1px solid #E5E5E5;

    		.mdl-tabs__tab-bar{
    			border-bottom: 1px solid #E5E5E5;
				
    		}
    	}
	}

	.product-details-container {
    	width: calc(100% - 625px);
    	background: white;
    	padding: 20px;
    	// border-radius: 5px;
    	box-sizing: border-box;
    	//height: min-content;
    	height: max-content;
    	border: 1px solid #E5E5E5;

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		   height: 100%;
		}

    	@include mq("tablet", "max"){
    		width: 100%;
    		margin-top: 10px;
    	}

    	.select-option-btn:after {
		    font-size: 24px;
		}
	}
}