/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
// 

$body-max-width:                    1175px;


//
// General: Color
//

$brand-color:                       #53565A;
$brand-color-secondary:             #51657D;

$brand-color-btn-primary:           #51657D;
$brand-color-btn-primary-hover:     #43566D;

$brand-color-btn-secondary:         #53565A;
$brand-color-btn-secondary-hover:   #424447;

$accent-color: 						#009639;

$link-color:						#004990;
$link-hover: 						#002548;

$primary-font-color:                #53565A;
$h1-font-color:                     $brand-color;
$h2-font-color:                     $primary-font-color;


//
// General: Font
// 

$h1-font-size:                      30px;
$h2-font-size:                      21px;
$primary-font-face:             	'Montserrat', 'sans-serif';
$secondary-font-face:        	   	'Montserrat', 'sans-serif';
$drawer-font:                    	$primary-font-face;

//
// Header
//

$header-background:                 #fff;
$header-height:                     175px;
$header-top-height:                 82px;
$header-main-height:                50px;
$top-bar-height:					43px;
$header-mobile-height:              56px;
 
$header-tablet-height:              56px;
$header-phone-height:               56px;
$header-logo-padding:               10px 10px 10px 27px;

$header-minicart-badge-color:       #53565A;
$header-minicart-badge-text-color:  #FFF;
$header-minicart-badge-border:      #FFF; 

$header-top-link-hover-border:      2px solid rgba(255,255,255,0.5);
$header-icons-color:                #7A9A01;
$header-search-font-color:          $primary-font-color;
$header-search-underline-color:     $header-icons-color;


//
// Mobile Drawer Menu
//

$drawer-mobile-background:          #FFF;


//
// Footer
//

$footer-background:                 #323537;
