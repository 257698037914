/*
 * Flex UX Framework
 *
 * Filename: checkout_multibilltoaddr.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container {
    display: flex;
    flex-wrap: wrap;

    @include mq("tablet", "min") {
    	margin: 20px;
  	}

  	i {

  		&.material-icons {
  			color: #7A9A01;
  		}
  	}

  	.checkout-main {
  		display: flex;
		  flex-flow: column;
      width: 100%;

		@include mq("tablet", "min") {
        flex: 75 1;
        margin-right: 20px;
        height: 100%;
  		}

  		&-top {
  			margin-bottom: 20px;
        background: #fff;
  		}

  		&-bottom {
  			flex: 1 1 100%;
        background: #fff;
			
			.text-wrapper{
				.icon-wrapper{
					.material-icons{
						display: none;
					}
				}
			}
  		}

      p{
        font-size: 13px;
        line-height: 20px;
      }
  	}

  	.checkout-side {
  		flex: 25 1;
        @include mq("tablet", "max") {
          margin-top: 10px;
        }
  		&-top {
  			flex-flow: column;
			   padding: 0;

			&--addresses {
				padding: 20px!important;
				min-height: 125px;

				h2 {
					margin-bottom: 0;
					padding-left: 0;
					padding-right: 0;
					padding-top: 0;
					padding-bottom: 3px !important;
				}
			}
  		}

  		&-bottom {
  			display: flex;
			flex: 1 1 100%;
			flex-flow: column;
			padding: 0;
			margin-top: 20px;
			align-items: center;

			button{
				width: 275px;
				padding: 0px 15px;
			}
  		}
  	}
}

