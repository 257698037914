/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.cat-spotlight {
	//margin-top: 129px;
	// margin-top: 50px;
	// // background: #009639;
    // background-size: cover;
	display: none;
	// justify-content: center;
	// align-items: center;
	//background: url(../images/CAT_desktop_200_1024.jpg);
	
	@include mq('tablet', 'max') {
		//background: url(../images/CAT_tablet_175_768.jpg);
		//height: 150px;
	}

	@include mq('phone-wide', 'max') {
		//background: url(../images/CAT_mobile_150_400.jpg);
		//height: 125px;
	}
	@include mq('phone-small', 'max') {
		//background: url(../images/CAT_mobile_small_125_321.jpg);
		//height: 100px;
	}

	@include mq('tablet', 'max') {
		// margin-top: $header-tablet-height;
		margin-top: 0;
	}

	.wrapper{
		width: 100%;
		height: 100%;
		// max-width: 1175px;
		display: flex;
		justify-content: center;
		align-items: center;

		@include mq('tablet-small', 'min'){
			background: url(../images/cat-items.png)no-repeat scroll left;
		}
		.title{
			color: #fff;
			font-size: calc(18px + 2vw);
			font-family: $primary-font-face;
			font-weight: 800;
			text-shadow: 0 0 12px #000000;
			text-align: center;
		}

		img{
			height: calc(32px + 6vw);
			max-height: 125px;
		}
	}
}
