/*
 * Flex UX Framework
 *
 * Filename: terms.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.terms {
	main {
		background: #fff !important;
	}

	.page-body {
		max-width: 600px;
        margin: 0px auto;
        padding: 12px 12px 50px 12px;
	}

	h1 {
		margin-bottom: 10px;
	}

	h1:not(:first-of-type) {
		font-size: 23px;
		font-family: $secondary-font-face;
		color: $primary-font-color;
		letter-spacing: 0;
		font-weight: 400;
		text-transform: uppercase;
		margin-bottom: 5px;
    }

    p {
    	font-size: 14px;
    	line-height: 1.5em;
    	margin-bottom: 20px;
	}
}